import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import logger from '@/lib/logger'

const errorHandler = (error) => {
  if (typeof error === 'function') {
    logger.error(`${Object(error).status} ${Object(error).message}`)
  }
  if ('response' in error && !!error.response) {
    logger.error(
      `Unable to ${error.request.method} ${error.config?.url}: ${JSON.stringify(
        error.response.data,
      )}`,
    )
  } else if ('request' in error && !!error.request) {
    logger.error(
      `Unable to ${error.config?.method?.toUpperCase()} ${error.config?.url}: ${error.message}`,
    )
  }

  const result = {
    status: parseInt(error.code || StatusCodes.INTERNAL_SERVER_ERROR + ''),
    data: { error },
  }

  return result
}

const createClient = (options) => {
  const client = axios.create(options)

  client.interceptors.request.use((request) => {
    logger.info(
      `${request.url}, params: ${JSON.stringify(request.params)}, method: '${request.method}'`,
    )
    return request
  })

  const get = async (endpoint, request) => {
    let response
    return client
      .get(endpoint, request)
      .then((_res) => (response = _res))
      .catch(errorHandler)
      .finally(() => response)
  }

  const post = async (path, body) => {
    let response
    return client
      .post(path, body)
      .then((res) => res)
      .catch(errorHandler)
      .finally(() => response)
  }

  return {
    get,
    post,
  }
}

export { createClient, errorHandler }
