import React from 'react'
import { Global, css } from '@emotion/react'

const defaultFonts = css`
  @font-face {
    font-family: 'Roboto-Bold';
    font-display: swap;
    src: url('/static/assets/fonts/Roboto-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'Roboto-Regular';
    font-display: swap;
    src: url('/static/assets/fonts/Roboto-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Roboto-Light';
    font-display: swap;
    src: url('/static/assets/fonts/Roboto-Light.woff') format('woff');
  }
`

const GlobalComponent = () => <Global styles={defaultFonts} />

export default GlobalComponent
