export const getQueryParamsFromUrl = (url) => {
  const index = url.indexOf('?')
  if (index < 0) return {}

  const paramsArray = url
    .substr(index + 1)
    .split('&')
    .filter((item) => item !== '')

  return paramsArray.reduce((acc, current) => {
    const arr = current.split('=')
    acc[arr[0]] = arr[1]
    return acc
  }, {})
}
