import { API_BASE_URL } from '@/constants/config/next'
import { createClient } from '@/utils/network'

const apiClientCore = createClient({
  baseURL: API_BASE_URL.API,
})

/**
 * Returns the type API for the given [Endpoint](./Routes).
 *
 * ```ts
 * // lib/api/example/index.ts
 * import PAGES from '@/constants/pages';
 * import API from '@/lib/api';
 *
 * const exampleApiClient = API(PAGES.ENDPOINTS.EXAMPLE)
 *
 * const apiExamplePost = (request: Api.Example.Request.Post) => {
 *   return exampleApiClient.post<Api.Example.Body, Api.Example.Response.Post>(req)
 * }
 *
 * export { apiExamplePost }
 *
 * // pages/api/example.api.ts
 * const exampleApi: NextApiHandler<Api.Example.Response> = async (req, res) => {
 *   const response = await getResults(req.body);
 *   return res.status(status).json(response.data);
 * };
 *
 * export default exampleApi;
 * ```
 *
 * @name apiClientCore
 * @memberof API
 * @param endpoint
 */
const apiCore = (endpoint) => {
  /**
   * Typed GET implementation for the standard API interface.
   *
   * @name apiCoreGet
   * @memberof API
   * @param request
   */
  const apiCoreGet = async (request) => {
    return apiClientCore.get(endpoint, Object(request))
  }

  /**
   * Typed POST implementation for the standard API interface.
   *
   * @name apiCorePost
   * @memberof API
   * @param request
   */
  const apiCorePost = async (request) => {
    return apiClientCore.post(
      endpoint,
      Object.assign(Object.create(null), request.body),
    )
  }

  return {
    get: apiCoreGet,
    post: apiCorePost,
  }
}

const api = apiCore

export default api
