import isBrowser from './isBrowser'
import redirect from './redirect'
import parseUrl from './parseUrl'
import { getQueryString } from './getQueryString'
import { getUTMParamsFromQuery } from './getUTMParamsFromQuery'
import { getUTMParamsFromUrl } from './getUTMParamsFromUrl'
import { getGeoIpData } from './getGeoIpData'

export default {
  isBrowser,
  redirect,
  parseUrl,
  getQueryString,
  getUTMParamsFromQuery,
  getUTMParamsFromUrl,
  getGeoIpData,
}
