export default {
  largeBold: {
    fontFamily: 'bold',
    fontSize: '25px',
    lineHeight: '29.3px'
  },
  medium: {
    fontFamily: 'regular',
    fontSize: ['18px', '20px'],
    lineHeight: ['21.09px', '23.44px']
  },
  mediumBold: {
    fontFamily: 'bold',
    fontSize: ['18px', '20px'],
    lineHeight: ['21.09px', '23.44px']
  },
  small: {
    fontFamily: 'regular',
    fontSize: ['16px', '18px'],
    lineHeight: ['18.75px', '21.09px']
  },
  smallBold: {
    fontFamily: 'bold',
    fontSize: ['16px', '18px'],
    lineHeight: ['18.75px', '21.09px']
  },
  label: {
    fontFamily: 'regular', 
    fontSize: '10px',
    lineHeight: '11.72px'
  }
}
