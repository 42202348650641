/**
 * Next.js' <a href="https://nextjs.org/docs/pages/building-your-application/data-fetching/incremental-static-regeneration" target="_blank">Incremental Static Regeneration</a>
 * rebuild interval in _seconds_.
 *
 * @name NEXTJS_SSG_REVALIDATE_RATE_SEC
 * @memberof Configuration
 */
const SSG_REVALIDATE_RATE_SEC = 10

/**
 * Predefined base URLs or paths for the network client..
 *
 * @name NEXTJS_BASE_URL
 * @memberof Configuration
 */
const API_BASE_URL = {
  API: '/api',
  CLIENT: process.env.PROJECT_URL || 'taylormadegolf.partnership-hub.com',
}

/**
 * The address to fallback to when the client IP cannot be determined.
 *
 * @name NEXTJS_FALLBACK_CLIENT_IP
 * @memberof Configuration
 */
const GEO_FALLBACK_CLIENT_IP = '0.0.0.0'

/**
 * Permanent query parameters that are required to be present in some cases.
 *
 * @name NEXTJS_ROUTER_REQUIRED_QUERY_PARAMS
 * @memberof Configuration
 */
const ROUTER_REQUIRED_QUERY_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export {
  SSG_REVALIDATE_RATE_SEC,
  API_BASE_URL,
  ROUTER_REQUIRED_QUERY_PARAMS,
  GEO_FALLBACK_CLIENT_IP,
}
