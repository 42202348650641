export default {
  borderRadius: '0px',
  border: '1px solid #ced4da',
  outline: 'none',
  '::placeholder': {
    color: 'primary',
    fontFamily: 'light',
  },
  ':focus': {
    border: '1px solid #97D9F6',
  },
}
