import { LOCALES } from '@/constants/config/locales'

function parseUrl(url) {
  const locale = url.split('/').find((part) => LOCALES.includes(part))
  return {
    locale,
    url,
  }
}
export default parseUrl
