const selectStyles = {
  gridColumnStart: '1',
  gridColumnEnd: '3',
  label: {
    color: 'white',
    fontFamily: 'bold',
    backgroundColor: '#191A19',
    pb: '8px',
  },
  '[class$="placeholder"]': { color: 'grey', fontFamily: 'regular' },
  'span+div': {
    borderColor: 'inherit',
    borderRadius: '30px',
  }, // workaround to style the dropdown
  ['.error-select']: { borderColor: 'error' },
}

export default {
  container: {
    contact: {
      display: 'grid',
      rowGap: '15px',
      columnGap: '20px',
      width: '100%',
      color: 'black',
      label: {
        color: 'white',
      },
    },
    survey: {
      display: 'grid',
      rowGap: '15px',
      columnGap: '20px',
    },
  },
  inputContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    label: {
      color: 'white',
      fontFamily: 'bold',
      backgroundColor: '#191A19',
      pb: '8px',
    },
    ['.error-input']: {
      borderColor: 'error',
    },
  },
  input: {
    background: 'white',
    borderRadius: '30px',
    '--theme-ui-input-autofill': {
      backgroundColor: 'white',
    },

    ':-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
      '-webkit-text-fill-color': '#black !important',
    },
    ':-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 30px #FFF inset !important',
    },
    ':-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
      '-webkit-text-fill-color': '#black !important',
    },
    ':-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
    },
  },
  errorMessage: {
    color: 'error',
    marginTop: '9px',
    fontStyle: 'italic',
  },
  countryContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    label: {
      color: 'white',
      fontFamily: 'bold',
      backgroundColor: '#191A19',
      pb: '8px',
    },
    '[class$="placeholder"]': { color: 'gray', fontFamily: 'regular' },
    'span+div': { borderRadius: '30px' }, // workaround to style the dropdown
  },
  countyContainer: selectStyles,
  countrySubdivisionContainer: selectStyles,
  selectContainer: selectStyles,
  select: {
    control: { borderRadius: 'none' }, //styling border not working
    placeholder: { fontFamily: 'Roboto-Light', color: 'primary' },
  },
  phoneContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  checkboxContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  button: {
    background: 'primary',
    width: '50%',
  },
  checkbox: {
    div: {
      label: {
        div: {
          'input:checked~svg': {
            color: 'secondary',
          },
          'input~svg': {
            color: 'secondary',
          },
          p: {
            fontFamily: 'light',
            fontSize: '16px',
            lineHeight: '22px',
            a: { fontFamily: 'regular', color: 'primary' },
          },
        },
      },
    },
  },
  submitContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    display: 'flex',
    justifyContent: 'center',
    width: ['100%', '97%'],
    button: {
      background: 'primary',
      color: 'textDark',
      fontSize: '18px',
      lineHeight: '18px',
      mt: '10px',
      mb: '30px',
      mx: '0px',
      p: '15px 60px',
      borderRadius: '77px',
      '&:hover': {
        color: 'textLight',
        background: 'secondary',
      },
      marginBottom: '75px',
    },
  },
}
