import box from './box'
import buttons from './buttons'
import colors from './colors'
import fontWeights from './fontWeights'
import fonts from './fonts'
import forms from './forms'
import grids from './grids'
import images from './images'
import input from './input'
import layout from './layout'
import label from './label'
import links from './links'
import sizes from './size'
import select from './select'
import text from './text'
import icons from './icons'
import sliders from './sliders'
import styles from './styles'
import breakpoints from './breakpoints'

export default {
  colors,
  fonts,
  fontWeights,
  buttons,
  text,
  input,
  forms,
  links,
  images,
  sizes,
  layout,
  label,
  box,
  grids,
  icons,
  sliders,
  styles,
  select,
  breakpoints,
}
