var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Jg--6My_mc9Y2AXxlw7Ik"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { extraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import {
  SENTRY_ERROR_SAMPLE_RATE,
  SENTRY_REPLAY_SAMPLE_RATE,
  SENTRY_TRACE_SAMPLE_RATE
} from '@/constants/config/sentry';

const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const clientIntegrations = [
  new Sentry.Replay({
    maskAllText: true,
    blockAllMedia: true
  }),
  new Sentry.browserTracingIntegration(),
  extraErrorDataIntegration()
];

const clientConfig = {
  debug: false,
  dsn: SENTRY_ENVIRONMENT ? SENTRY_DSN : '',
  environment: SENTRY_ENVIRONMENT,
  integrations: clientIntegrations,
  replaysSessionSampleRate: SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY_ERROR_SAMPLE_RATE,
  sendDefaultPii: true,
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE
};

Sentry.init(clientConfig);
