import PAGES from '@/constants/pages'
import API from '@/lib/api'

const geoIpClient = API(PAGES.ENDPOINTS.GEOIP)

const geoIpGet = (request) => geoIpClient.get(request)

export default geoIpClient

export { geoIpGet }
