export const LOCALES = ['en', 'en_IE', 'fr', 'se', 'de']
export const ISO_CODE_MAP = {
  GB: 'en',
  IE: 'en_IE',
  FR: 'fr',
  SE: 'se',
  DE: 'de',
}
export const DEFAULT_LOCALE = 'en'
export const DEFAULT_ISO_CODE = 'GB'

export default { LOCALES, DEFAULT_LOCALE, ISO_CODE_MAP }
