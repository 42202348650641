export default {
  images: {
    bagHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '50% !important',
      minHeight: '20% !important',
    },
  },
  header: {
    width: '800px',
    height: '500px',
    marginTop: '10%',
  },
  homeHeaderMobile: {
    width: '100%',
    minHeight: '40% !important',
  },
  homeHeaderDesktop: {
    width: '100%',
    minHeight: '40% !important',
  },
  homePageHeader: {
    //marginTop: '10% !important',
    minWidth: '60% !important',
    width: '60% !important',
    height: '100%',
  },
  defaultClub: {
    width: '100%',
    height: '75%',
  },
  bagHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '50% !important',
  },
}
